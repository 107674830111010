import { defineStore } from 'pinia'
import { ref, toRaw } from 'vue'



export const useUtilsForm = defineStore('useUtilsForm', () => {
    enum FocusableElement {
      INPUT,
      TEXTAREA,
      SELECT,
      BUTTON,
      A,
      AREA,
    }

    function filterInputsForm(e: any, c?: boolean) {
      const target = e.target ?? e
      const inputs = target.querySelectorAll('input, textarea')
      const completedInputs = [...inputs].filter((input: any) => (input.value !== '' || input.dataset.value !== '') && (input.id !== '' && input.dataset.id !== '' || input.checked))
      const verifiedInputs:any = {} ;
      // console.log(completedInputs)
      completedInputs.map((input: any) => {
            let value = input.value;
            let id = input.id;
            // headless ui recover value 
            if(input.type == "radio"){
             id= input.dataset.groupid;
            }
            if(input.dataset.id){
                id = input.dataset.id;
                value = target.querySelector(`div#${id} input[name="assignee[id]"]`)?.value;
                if(!value){
                  
                  const tmp = target.querySelectorAll(`div#${id} input[name*="assignee"]`);
                  
                  const tmp2: any[] = [];
                  [...tmp].map((input: any) => {
                    if(input.name.slice(- 4) == '[id]'){
                      tmp2.push(input.value);
                    }
                  });
                  value = tmp2
                };
            }
            if(input.dataset.value && !input.dataset.id){
              try {
                value = JSON.parse(input.dataset.value);
              }
              catch (e) {
                value = input.dataset.value;
              }
            }
            if(value) verifiedInputs[`${id}`] = value;
            
      });
      for (const [key, value] of Object.entries(verifiedInputs)) {
        if(value == 'on'){
          verifiedInputs[key] = target.querySelector(`input#${key}`).checked;
        }
      }
      return verifiedInputs;
    }

    function unTriggerError(id:string){
      const el = document.getElementById(id) ?? null;
      if(el){
        el.classList.remove('invalidInput')
      }
    }

    function triggerError(id:string){
      const el = document.getElementById(id) ?? null;
      if(el){
        el.scrollIntoView({block: "center"});
        
        const tags = Object.keys(FocusableElement).filter(e => Number.isNaN(Number(e)))

        el.focus();
        el.classList.add('invalidInput');

        // add event listener click that use unTriggerError(id) and that remove the event listener
        // el.addEventListener('change', unTriggerError(id), {once: true});


        if(!tags.includes(el.tagName)){
          tags.forEach(tag => {
            const tmp: any = el.querySelector(tag);
            if(tmp){
              tmp.focus();
            }
          })
        }
      }
    }


    return { filterInputsForm, unTriggerError, triggerError}
});


export const useUtilsRandomize= defineStore('useUtilsRandomize', () => {
  class RNG {
    private m: number = 0x80000000;
    private a: number = 1103515245;
    private c: number = 12345;
    private state: number;
  
    constructor(seed?: number) {
      this.state = seed ? seed : Math.floor(Math.random() * (this.m - 1));
    }
  
    public nextInt(): number {
      this.state = (this.a * this.state + this.c) % this.m;
      return this.state;
    }
  
    public nextFloat(): number {
      return this.nextInt() / (this.m - 1);
    }
  
    public nextRange(start: number, end: number): number {
      const rangeSize = end - start;
      const randomUnder1 = this.nextInt() / this.m;
      return start + Math.floor(randomUnder1 * rangeSize);
    }
  
    public choice<T>(array: T[]): T {
      return array[this.nextRange(0, array.length)];
    }
  }
  
  function randomize(questions: any, username: string) {
      const seed = username.split('').map((c: string) => (c.charCodeAt(0))).reduce((a, b) => a + b, 0)
      const rng = new RNG(Number(seed));
      const random:any[] = []
      const values = toRaw(questions).values
      
      const length = values.length

      for (let i = 0; i < length; i++) {
        const rValue = rng.nextRange(0, values.length);
        random.push(values[rValue])
        values.splice(rValue, 1)
      }
      toRaw(questions).values = random

      return questions
  }
  return {  randomize }
});


export const useGlobalStore = defineStore('useGlobalStore', () => {
  const GlobalAppLoading = ref(false)


  return { GlobalAppLoading }
});



const disconnected: string[] = [
  "route.home",
  "route.activations",
  "route.404",
  "route.forgetpwd",
  "route.changepwd"
]
const user : string[] = [
  "route.home",
  "route.404",
  "route.changepwd",
  "route.profile"
]

const formateur : string[] = [
  "route.inscription.formateur",
  "route.cloud",
  "route.forum",
  "route.quiz",
  "route.question",
  "route.block.am",
  "route.block.pm",
  // "route.airtable",
  // "route.airtable.publications",
  // "route.airtable.thematiques",
  // "route.airtable.thematiques.climat",
  // "route.airtable.thematiques.cooperation",
  // "route.airtable.thematiques.stereotype",
  // "route.airtable.thematiques.influence",
  // "route.airtable.thematiques.eccipe",
  // "route.airtable.thematiques.feedback",
  // "route.airtable.editor",
  // "route.airtable.ressource"
]

const forme: string[] = [
  "route.inscription.formateur",
  "route.cloud",
  // "route.airtable",
  // "route.airtable.thematiques",
  // "route.airtable.thematiques.climat"
]

const inspecteur : string[] = [
  "route.inscription.inspecteur",
  "route.cloud",
  "route.forum",
  "route.quiz",
  "route.question",
  "route.block.am",
  "route.block.pm",
  // "route.airtable",
  // "route.airtable.publications",
  // "route.airtable.thematiques",
  // "route.airtable.thematiques.climat",
  // "route.airtable.thematiques.cooperation",
  // "route.airtable.thematiques.stereotype",
  // "route.airtable.thematiques.influence",
  // "route.airtable.thematiques.eccipe",
  // "route.airtable.thematiques.feedback",
  // "route.airtable.editor",
  // "route.airtable.ressource"
]

const chefetablissement : string[] = [
  "route.inscription.chefetablissement",
  "route.cloud",
  "route.forum",
  
]

const directeureafc : string[] = [
  "route.inscription.directeureafc",
  "route.cloud",
  "route.forum",
  
]

const invite : string[] = [
  "route.cloud",
  "route.forum"
]

const chercheur : string[] = [
  "route.cloud",
  "route.forum",
  "route.wordcloud",
  "route.charts",
  "route.directory"
]

const celinebuchs  : string[] = [
  "route.cloud",
  "route.forum",
  "route.wordcloud",
  "route.charts",
  "route.directory",
  "route.airtable",
  "route.airtable.publications",
  "route.airtable.thematiques",
  "route.airtable.thematiques.climat",
  "route.airtable.thematiques.cooperation",
  "route.airtable.thematiques.stereotype",
  "route.airtable.thematiques.influence",
  "route.airtable.thematiques.eccipe",
  "route.airtable.thematiques.feedback",
  "route.airtable.editor",
  "route.airtable.ressource"
]

const equipe : string[] = [
  "route.cloud",
  "route.forum",
  "route.wordcloud",
  "route.charts",
  "route.quiz",
  "route.question",
  "route.block.am",
  "route.block.pm",
  "route.dashboard",
  "route.dashboard.users",
  "route.dashboard.stats",
  "route.dashboard.mails",
  "route.dashboard.sentmails",
  "route.directory",
  "route.airtable",
  "route.airtable.publications",
  "route.airtable.thematiques",
  "route.airtable.thematiques.climat",
  "route.airtable.thematiques.cooperation",
  "route.airtable.thematiques.stereotype",
  "route.airtable.thematiques.influence",
  "route.airtable.thematiques.eccipe",
  "route.airtable.thematiques.feedback",
  "route.airtable.editor",
  "route.airtable.ressource"
]

const modo : string[] = [

]

const admin : string[] = [
  "route.activations",
  "route.inscription.formateur",
  "route.inscription.inspecteur",
  "route.inscription.chefetablissement",
  "route.inscription.directeureafc",
  "route.cloud",
  "route.forum",
  "route.wordcloud",
  "route.charts",
  "route.quiz",
  "route.question",
  "route.block.am",
  "route.block.pm",
  "route.directory",
  "route.dashboard",
  "route.dashboard.users",
  "route.dashboard.stats",
  "route.dashboard.mails",
  "route.dashboard.sentmails",
  "route.forgetpwd",
  "route.airtable",
  "route.airtable.publications",
  "route.airtable.thematiques",
  "route.airtable.thematiques.climat",
  "route.airtable.thematiques.cooperation",
  "route.airtable.thematiques.stereotype",
  "route.airtable.thematiques.influence",
  "route.airtable.thematiques.eccipe",
  "route.airtable.thematiques.feedback",
  "route.airtable.editor",
  "route.airtable.ressource"

  

]
export const usePermissions = {
  "disconnected":disconnected,
  "user":user,
  "invite":invite,
  "chefetablissement":chefetablissement,
  "formateur":formateur,
  "forme":forme,
  "inspecteur":inspecteur,
  "chercheur":chercheur,
  "directeureafc":directeureafc,
  "celinebuchs":celinebuchs,
  "equipe":equipe,
  "modo":modo,
  "admin":admin
}
